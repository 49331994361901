<template>
  <v-container class="pa-0" fluid>
    <BannerContabilidad />
    <Gestion />
    <InformationSec />
    <CTACont />
    <AllYouNeed />
  </v-container>
</template>

<script>
  import BannerContabilidad from '../components/sections/BannerContabilidad.vue'
  import Gestion from '../components/sections/Gestion.vue'
  import InformationSec from '../components/sections/InformationSec.vue'
  import CTACont from '../components/sections/CTACont.vue'
  import AllYouNeed from '../components/sections/AllYouNeed.vue'

  export default {
    name: 'Contabilidad',

    components: {
      BannerContabilidad,
      Gestion,
      InformationSec,
      CTACont,
      AllYouNeed
    },
  }
</script>