<template>
  <v-container class="bg-section py-16" fluid>
    <v-row>
      <v-col :md="4" class="">
        <img
          :src="require(`@/assets/contabilidad/woman-banner.png`)"
          height="480px"
          class="ml-16 hidden-md-and-down"
          contain
        />
      </v-col>
      <v-col cols="11" :lg="4" class="text-center mx-2 mx-sm-10 mx-md-0">
        <h1 class="mb-6">Tu contabilidad en tiempo real</h1>
        <p class="">
          Con Acist tienes tu contabilidad siempre al día. Automatiza, supervisa
          y toma mejores decisiones para tu negocio.
        </p>
        <div class="text-center mt-14">
              <v-btn
                rounded
                x-large
                class="mainButton"
                dark
              >
                Probar Gratis
              </v-btn>
        </div>
      </v-col>
      <v-col :md="4" class="hidden-md-and-down">
        <img
          :src="require(`@/assets/contabilidad/man-banner.png`)"
          height="480px"
          class="mr-16"
          contain
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "BannerContabilidad",

  data: () => ({}),
};
</script>

<style scoped>
.bg-card {
  background: #ffffff;
  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.12);
  border-radius: 20px;
}
</style>
