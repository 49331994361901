<template>
  <v-container class="mb-16" fluid>
    <v-row class="mb-10 justify-center">
      <v-col cols="10" class="bg-card">
        <div class="mx-md-12 mb-4">
          <v-row>
            <v-col :md="3">
            </v-col>
            <v-col cols="10" :md="6">
              <h2 class="pb-2 mt-14 white--text text-center">
                Todo lo que necesitas para una contabilidad completa
              </h2>
            </v-col>
            <v-col :md="3">
            </v-col>
          </v-row>
          <v-row class="mt-14">
            <v-col cols="12" :md="6" :lg="4">
              <v-list-item-group color="primary" class="mx-2">
                <v-list-item v-for="(item, i) in points" :key="i" class="mb-4">
                  <v-list-item-icon class="mr-3">
                    <div color="white">
                      <v-img
                        :src="require('@/assets/icons/point-w.svg')"
                        contain
                        height="20"
                      />
                    </div>
                  </v-list-item-icon>
                  <v-list-item-content style="display: inline">
                    <h5 class="white--text">{{ item.title }}</h5>
                    <p class="white--text font-weight-light">
                      {{ item.subtitle }}
                    </p>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-col>
            <v-col cols="12" :md="6" :lg="4">
              <v-list-item-group color="primary" class="mx-2">
                <v-list-item v-for="(item, i) in points2" :key="i" class="mb-4">
                  <v-list-item-icon class="mr-3">
                    <div color="white">
                      <v-img
                        :src="require('@/assets/icons/point-w.svg')"
                        contain
                        height="20"
                      />
                    </div>
                  </v-list-item-icon>
                  <v-list-item-content style="display: inline">
                    <h5 class="white--text">{{ item.title }}</h5>
                    <p class="white--text font-weight-light">
                      {{ item.subtitle }}
                    </p>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-col>
            <v-col cols="12" :md="6" :lg="4">
              <v-list-item-group color="primary" class="mx-2">
                <v-list-item v-for="(item, i) in points3" :key="i" class="mb-4">
                  <v-list-item-icon class="mr-3">
                    <div color="white">
                      <v-img
                        :src="require('@/assets/icons/point-w.svg')"
                        contain
                        height="20"
                      />
                    </div>
                  </v-list-item-icon>
                  <v-list-item-content style="display: inline">
                    <h5 class="white--text">{{ item.title }}</h5>
                    <p class="white--text font-weight-light">
                      {{ item.subtitle }}
                    </p>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-col>
          </v-row>
          <v-row class="mb-12">
            <v-col :md="3">
            </v-col>
            <v-col :md="6">
              <v-btn
                text
                block
                x-large
                class="btn-cta white--text mt-12 pr-16 mr-16"
              >
                Solicita una demo
              </v-btn>
            </v-col>
            <v-col :md="3">
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "CTACont",

  components: {},
  data: () => ({
    points: [
      {
        title: "Asientos personalizados",
        subtitle:
          "Crea los asientos  para no perder tiempo introduciendo siempre la misma información.",
      },
      {
        title: "Períodos Contables",
        subtitle:
          "Accede a los periodos contables online, obteniendo de forma inmediata la información.",
      }
    ],
    points2: [
      {
        title: "Asientos predefinidos",
        subtitle:
          "Crea asientos predefinidos para conciliar tus movimientos bancarios de forma sencilla.",
      },
      {
        title: "Estados Financieros",
        subtitle:
          "Personaliza la Cuenta de Pérdidas y Ganancias y el Balance de Situación según tus necesidades.",
      }
    ],
    points3: [
      {
        title: "Gestión del Plan Contable",
        subtitle:
          "Actualiza automáticamente el plan general contable para que cumplas con la normativa.",
      },
      {
        title: "Inmovilizados",
        subtitle:
          "Gestiona tus inmovilizados de forma fácil y sencilla con Acist.",
      }
    ],
  }),
};
</script>

<style scoped>
p {
  line-height: 1.6;
}
.bg-card {
  background: #141e56;
  border-radius: 20px;
}
.icon-banner {
  background-position: left;
}
.btn-cta {
    background: #5A88FE;
    box-shadow: 0px 1px 18px rgba(255, 255, 255, 0.5);
    border-radius: 40px;
}
</style>
