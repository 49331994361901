<template>
  <v-container class="bg-section" fluid>
    <v-row class="justify-center align-center mb-sm-14">
      <v-col cols="11" :md="6" class="mt-4">
        <div class="mx-6 ml-lg-16 mb-md-14">
          <h6 class="ml-lg-16 pl-lg-16">GESTIÓN INTELIGENTE Y ACTUALIZADA</h6>
          <h2 class="b-6 ml-lg-16 pl-lg-16">
            Automatiza y simplifica tu contabilidad
          </h2>
          <p class="ml-lg-16 pl-lg-16">
            Lleva tu contabilidad de forma prácticamente automática. La
            tecnología de Acist integra y conecta toda la información de la
            plataforma para ofrecerte una gestión más inteligente y siempre
            actualizada.
          </p>
          <v-list-item-group color="primary" class="ml-lg-16 pl-lg-16 mt-6">
            <v-list-item v-for="(item, i) in points" :key="i" class="mb-4">
              <v-list-item-icon class="mr-lg-3">
                <div color="white">
                  <v-img
                    :src="require('@/assets/icons/point-b.svg')"
                    contain
                    height="20"
                  />
                </div>
              </v-list-item-icon>
              <v-list-item-content style="display: inline">
                <h5>{{ item.title }}</h5>
                <p class="font-weight-light">
                  {{ item.subtitle }}
                </p>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </div>
      </v-col>
      <v-col cols="11" :md="6" class="cont-img">
        <v-img
          :src="require('@/assets/contabilidad/automatiza.png')"
          contain
          height="600"
          class="ml-md-12"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Gestion",

  data: () => ({
      points: [
      {
        title: "Contabilidad automática de tus facturas y tickets",
        subtitle:
          "Olvídate de contabilizar los movimientos uno a uno. Las facturas recibidas se contabilizan simplemente enviándolas a un buzón de emai",
      },
      {
        title: "Conciliación bancaria automática",
        subtitle:
          "Conecta tus bancos en Acist de forma totalmente segura, y no tendrás que ir introduciendo los movimientos bancarios manualmente.",
      },
      {
        title: "Gestión de Libros Contables",
        subtitle:
          "Gestiona el Libro Diario, el Libro Mayor, y el Libro de Sumas y Saldos sin esfuerzo. ",
      }
    ],
  }),
};
</script>

<style scoped>
.cont-img {
  text-align-last: left;
}
</style>
