<template>
  <v-container class="bg-section mb-16 py-sm-16" fluid>
    <v-row class="mb-16">
      <v-col cols="12" :md="4" :lg="5" class="pl-16 ml-16 pr-4 hidden-sm-and-down">
        <v-img
          :src="require('@/assets/contabilidad/man-laptop.png')"
          contain
          height="490"
          class="mr-12"
        />
      </v-col>
      <v-col :md="7" :lg="6" class="mr-4">
        <v-row class="mx-4">
          <h6>INFORMACIÓN SEGURA Y ACCESIBLE</h6>
          <h2 class="mr-14 mb-6">Información contable siempre actualizada</h2>
          <p class="">
            Consulta y supervisa toda la información contable de tu negocio
            desde Acist. Siempre accesible, al día y lista para descargar en un
            clic.
          </p>
        </v-row>
        <v-row class="mt-14">
          <v-col cols="11" :sm="5"  class="bg-card text-center mx-md-6">
            <v-img
              :src="require(`@/assets/contabilidad/libros-cont.png`)"
              height="160px"
            ></v-img>
            <h5 class="mt-6">Libros Contables</h5>
            <h6 class="mt-2">
              Ten siempre al día tus libros contables en formato digital, y
              descárgalos cuando los necesites.
            </h6>
          </v-col>
          <v-col cols="11" :sm="5" class="bg-card text-center mx-md-6">
            <v-img
              :src="require(`@/assets/contabilidad/cuentas-an.png`)"
              height="160px"
            ></v-img>
            <h5 class="mt-6">Cuentas Anuales</h5>
            <h6 class="mt-2">
              Genera de forma fácil y sencilla tus Cuentas Anuales(Balance,
              Cuenta de Pérdidas y Ganancias, Estados, y más).
            </h6>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <!-- End Block -->
    <hr class="mt-16" />
  </v-container>
</template>

<script>
export default {
  name: "InformationSec",

  data: () => ({}),
};
</script>

<style scoped>
.bg-card {
  background: #ffffff;
  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.12);
  border-radius: 20px;
}
</style>
